.main-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    width: 50%;
    height: 600px;
}

@media screen and (max-width:800px) {
    .container {
        width: 100%;
        height: 800px;
        margin: 0px 20px;
    }
}