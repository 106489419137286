.container{
    /* text-align: center; */
    padding: 20px 70px;
    margin-top: 80px;
}

.container h1{
    margin-bottom: 8px;
    margin-top: 5px;
}
.container p{
    margin-bottom: 5px;
    text-align: justify;
}

.container h3{
    margin: 30px 0px;
}

.container li,.container ol{
    text-align: left;
}