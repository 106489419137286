.main-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    width: 50%;
    height: 400px;
}

.form-lp {
    margin: 20px 0px;
}

.input-age {
    width: 250px !important;
    border: 1px solid #000 !important;
}

@media screen and (max-width:800px) {
    .container {
        width: 100%;
        height: 600px;
        margin: 0px 20px;
    }
}