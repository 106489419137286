
.footer-logo-first-text{
    font-family:  'Titan One', cursive;
     font-weight: 500; 
     font-size: 37px; 
     color: white;
}

.footer-logo-last-text{
    font-family:  'Titan One', cursive;
     font-weight: 500;
      font-size: 37px;
       color: red;
}