@import url('https://fonts.googleapis.com/css2?family=Titan+One&display=swap');

.logo-first-text {
    font-family: 'Titan One', cursive;
    font-weight: 500;
    font-size: 35px;

}

.logo-last-text {
    font-family: 'Titan One', cursive;
    font-weight: 500;
    font-size: 35px;
    color: red;
}

.side-nav-logo-first-text {
    font-family: 'Titan One', cursive;
    font-weight: 500;
    font-size: 25px;
    color: #fff
}

.side-nav-logo-last-text {
    font-family: 'Titan One', cursive;
    font-weight: 500;
    font-size: 25px;
    color: red;
}

.joinBtn {
    background-color: red !important;
    /* padding: 13px 21px !important; */
    border-radius: 3px !important;
    font-size: 15px !important;
    color: white !important;
}

.is-sticky a {
    text-decoration: none !important;
}

#joinPopup {
    padding: 15px !important;
    max-width: 500px !important;
    background-color: #1c2539;
overflow: auto;
}
.pxl-logo p span{
    font-size: 25px !important;
}
.pxl-heading {
    justify-content: center;
    /* display: flex; */
    margin-bottom: 10px;
}
.links-hover li a span:hover{
    color: rgb(203, 26, 26) !important;
}
.home span{
    font-weight: 600;
}
.home span:hover{
    color: rgb(203, 26, 26) !important;


}
.sidedrawer {
    background-color: #1c2539;
}

.menuList {
    display: flex;
    justify-content: center;
}

.menuList p {
    color: white !important;
    padding: 15px;
    text-align: center !important;

}

.menuList p:hover {
    color: red !important;

}

.navLinkMenu>a>span {
    color: red;
}

.home-opacity{
    /* opacity: 1 !important; */

}
.select-gender:hover{
    color: black;
}

@media screen and (max-width:335px) {
    #pxl-nav-mobile {
        display: flex !important;
    }
}

@media screen and (max-width:800px) {

    .logo-first-text,
    .logo-last-text {
        font-size: 25px !important;
    }
}
@media screen and (max-width:500px) {

    .logo-first-text,
    .logo-last-text {
        font-size: 30px !important;
    }
    .side-drawer-main{
        width: 100% !important;
    }

}
@media screen and (max-width:300px) {

    .logo-first-text,
    .logo-last-text {
        font-size: 20px !important;
    }
    #joinPopup {
        padding: 0 !important;
        overflow: auto;

    
    }

}